.sign-up__footer {
    display: grid;
    row-gap: 16px;
    grid-template-columns: 1fr;

    .yandex-button {
        width: 100%;
        display: grid;
        grid-template-columns: 24px auto;
        column-gap: 13.5px;
        align-items: center;
        justify-content: center;
        padding: 10px 24px;
        background: #000000;
        color: white;
        font-size: 14px;
        font-weight: 600;
        line-height: 1;
        border-radius: 12px;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    .btn-in {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        line-height: 1;
        border-radius: 12px;
        padding: 10px 24px;
        height: 44px;

    }

    .sign-up__separator {
        font-size: 14px;
        line-height: 18px;
        color: #85889e;
        text-align: center;
        position: relative;

        span {
            position: relative;
            background: rgb(77 78 99);
            padding: 0 24px;
            display: inline-block;
        }
    }

    .sign-up__separator:before {
        content: '';
        height: 1px;
        width: 100%;
        background: #daddee;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0;
    }
}