.chat_list {
    background: #202123 !important;
    z-index: 101!important;
}


.sidebar-top {
    grid-area: sidebar-top;
    border-bottom: 1px solid rgba(255, 255, 255, 0.42);
}

.sidebar-center {
    padding-top: 10px;
    grid-area: scroll-zone;
    overflow-y: scroll;
}

.sidebar-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.34);
    grid-area: sidebar-footer;
}

.sidebar {
    height: 100% !important;
    display: grid !important;
    grid-template-rows: 50px 1fr 130px;
    grid-template-areas: "sidebar-top" "scroll-zone" "sidebar-footer";
    @media (max-width: 768px) {
        position: fixed;
        left: 0;
        top: 0;
        transform: translateX(-200%);
        transition: .3s;
        &.active {
            transform: translateX(0);
        }
    }
}

.main-header {
    position: fixed;
    //background-color: red;
    top: 0;
    z-index: 99;
    //left: 260px;
    display: none;
    //width: calc(100% - 260px);

    @media (max-width: 768px) {
        display: flex !important;
        //align-items: center;
        left: 0;
        width: 100%;
        .header-nav {
            display: flex !important;
            align-items: center;
            width: 100%;
            background-color: #202123;
        }

        &.active {
            padding-left: 270px;
        }
    }
}

.chat-name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    display: flex;
}

.chat-item {
    padding: 0.5rem;

    //border: 1px solid #ffffff78;
    border-radius: 5px;
    margin-bottom: 10px;
}

.chat-active {
    background-color: #343541;
}

.item-chat-name {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inp-edit {
    background-color: #80808000;
    border: 1px solid #52565e;
    border-radius: 5px;
}