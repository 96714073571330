@import '~rsuite/styles/index.less';
@import "~kaiju.ui2/src/styles/index.less";

.frame {
    //height: 100vh;

    .rs-sidebar {
        background: #474960;
    }

    .rs-sidenav {
        flex: 1 1 auto;
        transition: none !important;
        border-top: 1px solid @B100;
        width: 100%;
    }

    .rs-content {
        padding: 0 10px;

        .rs-panel-header {
            .title {
                font-size: 18px;
            }

            .rs-breadcrumb {
                margin-bottom: 0;
            }
        }
    }

    .nav-toggle {
        border-top: 1px solid @B100;
    }

    .rs-sidenav-item,
    .rs-dropdown-item {
        &.active {
            color: @H500 !important;
        }

        &-icon {
            height: 1.3em !important;
            width: 1.3em !important;
        }
    }
}

.brand {
    padding: 10px 18px;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    text-transform: uppercase;

    a {
        text-decoration: none;
    }
}


//rewrite base color
@base-color: #34c3ff;

.text-muted {
    color: @B700;
}

.rs-sidebar {
    position: fixed;
    height: 100vh;
    z-index: 3;
}

.page-container {
    padding-left: 280px;
    transition: padding 0.5s;

    //&.container-full {
    //    padding-left: 60px;
    //}
    @media (max-width: 768px){
        padding-left: 0px!important;
        padding-top: 55px;
    }
}

.page-container-admin {
    padding-left: 260px;
    transition: padding 0.5s;

    //&.container-full {
    //    padding-left: 60px;
    //}
    @media (max-width: 768px){
        padding-left: 50px!important;
        padding-top: 55px;
    }
}


.bg-gradient-orange {
    background: linear-gradient(87deg, #fb6340 0, #fbb140 100%);
}


.bg-gradient-red {
    background: linear-gradient(87deg, #f5365c 0, #f56036 100%);
}

.bg-gradient-green {
    background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
}

.bg-gradient-blue {
    background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
}

.bg-gradient-mirage {
    background: linear-gradient(87deg, #16222a, #3a6073);
}

.rs-form-control-label {
    margin-bottom: 10px
}

.p-2 {
    padding: 0.7rem;
}

.pt-2 {
    padding-top: 0.7rem;
}

.pb-2 {
    padding-bottom: 0.7rem;
}

.pl-2 {
    padding-left: 0.7rem;
}

.pr-2 {
    padding-right: 0.7rem;
}

.p-3 {
    padding: 1rem;
}

.pt-3 {
    padding-top: 1rem;
}

.pb-3 {
    padding-bottom: 1rem;
}

.pl-3 {
    padding-left: 1rem;
}

.pr-3 {
    padding-right: 1rem;
}

//@media (max-width: 768px) {
//  .p-3 {
//    padding: 0;
//  }
//
//  .pt-3 {
//    padding-top: 0;
//  }
//
//  .pb-3 {
//    padding-bottom: 0;
//  }
//
//  .pl-3 {
//    padding-left: 0;
//  }
//
//  .pr-3 {
//    padding-right: 0;
//  }
//}

.m-3 {
    margin: 1rem;
}

.mt-3 {
    margin-top: 1rem;
}

.mb-3 {
    margin-bottom: 1rem;
}

.ml-3 {
    margin-left: 1rem;
}

.mr-3 {
    margin-right: 1rem;
}

.m-5 {
    margin: 2rem;
}

.mt-5 {
    margin-top: 2rem;
}

.mb-5 {
    margin-bottom: 2rem;
}

.ml-5 {
    margin-left: 2rem;
}

.mr-5 {
    margin-right: 2rem;
}

.p-4 {
    padding: 2rem;
}

.pt-4 {
    padding-top: 2rem;
}

.pb-4 {
    padding-bottom: 2rem;
}

.pl-4 {
    padding-left: 2rem;
}

.pr-4 {
    padding-right: 2rem;
}


.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;

}

.fs-22 {
    font-size: 22px;

}

.white {
    color: white;
}

.top-level-nav {
    background: #202123;
    //background: var(--rs-gray-900);
    left: 0;
    position: fixed !important;
    text-align: center;
    top: 0;
    width: 100vw;
    z-index: 10;
}

@media (min-width: 768px) {
    .top-level-nav {
        bottom: 0;
        left: 0;
        padding: 10px 0;
        position: fixed !important;
        width: 64px;
        z-index: 10;
    }
}

.top-level-nav .rs-icon-btn-circle {
    background: none;
    border: none;
    color: #fff;
    height: 46px;
    margin-bottom: 3px;
    margin-top: 3px;
    padding: 8px;
    width: 50px;
    margin-right: 8px;
}

@media (min-width: 768px) {
    .top-level-nav .rs-icon-btn-circle {
        margin-bottom: 10px;
        margin-top: 10px;
        margin-right: 0;

    }

}

.top-level-nav .rs-icon-btn-circle.visible-xs {
    display: none;
}

.top-level-nav-header {
    float: right;
}

@media (min-width: 768px) {
    .top-level-nav-header {
        float: none;
        margin-top: 20px;
    }
}

.main-container {
    margin-top: 52px;
    margin-left: 0px;
}

@media (min-width: 768px) {
    .main-container {
        margin-top: 0px;

        margin-left: 64px;
    }
}

@media (min-width: 768px) {
    .top-level-nav .rs-icon-btn-circle.visible-xs {
        display: inline-block;
    }
}

.rs-breadcrumb-item {
    font-size: 16px;
}

.text-center {
    text-align: center
}

@border-color: #9b9b9b;

.rs-input {
    border-color: @border-color;
}

.rs-input-group {
    border-color: @border-color;
}

.rs-picker-toggle {
    border-color: @border-color;
}

.rs-toggle-checked .rs-toggle-presentation {
    background-color: #54BF65;
}

.rs-toggle-checked .rs-toggle-presentation:hover {
    background-color: #54a955;
}

.rs-modal-wrapper {
    background-color: #696969c2;
}

.search-input.rs-input-group {
    border: none;
    max-width: 500px;

    input {
        background: #f7f7fa;
    }
}


@media (max-width: 768px) {
    .rs-drawer-right.rs-drawer-lg {
        width: 100%;
    }
}

.rs-input:disabled {
    background-color: var(--rs-input-disabled-bg);
    color: #646465;
}

.border {
    border: 1px solid #ced2d5;

}

.pointer {
    cursor: pointer;
}

.rs-panel-body {
    display: grid;
    padding: 10px!important;
}

.hidden {
    display: none;
}

.rs-icon-btn-support {
    z-index: 900 !important;
    position: fixed;
    width: 4rem;
    height: 4rem;
    right: 2rem;
    bottom: 2rem;
    border-radius: 50%;
    background-color: white !important;
    color: #272c36 !important;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

//& {
//    --rs-body: rgba(15, 19, 26, 0.85);
//}
// Enable dark mode
@enable-dark-mode: true;
// Enable high contrast mode
@enable-high-contrast: false;

//@B900-dark: rgba(15, 19, 26, 0.85);
//
@B900-dark: #343541;
@B800-dark: #3f4050;
@B700-dark: #4c4d60;
@B600-dark: #505167;
//

.rs-navbar-default {
    background-color: @B900-dark !important;
}

.bg-main {
    background-color: @B900-dark!important;
}

.panel-dark {
    background: @B800-dark!important;
}