//.sidebar-top {
//    grid-area: sidebar-top;
//    border-bottom: 1px solid rgba(255, 255, 255, 0.42);
//}

.messanger-center {
    //padding-top: 10px;
    grid-area: messanger-scroll-zone;
    overflow-y: scroll;
}

.messanger-footer {
    //border-top/: 1px solid rgba(255, 255, 255, 0.34);
    grid-area: messanger-footer;
}

.messanger {
    height: 100% !important;
    display: grid !important;
    grid-template-rows: 1fr min-content;
    grid-template-areas: "messanger-scroll-zone" "messanger-footer";

}

.message-block {
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 0;
    max-width: 52rem;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    border-top: 1px solid #ffffff63 !important;
    //left: 50%;
    @media (max-width: 1000px) {
        .text-info {
            width: 100% !important;
            padding: 0.7rem;

        }
    }
}

.send-button {
    background-color: #0ea47f !important;
    width: 200px;
    margin: auto;
    display: block!important;
    //margin-left: auto;
    //left: calc(50% - 200px);
    @media (max-width: 1000px) {
        margin: unset;
        width: 100%;


    }
}
.send-buy {
    background-color: #169de0!important;
    width: 200px;
    margin: auto;
    display: block!important;
    //margin-left: auto;
    //left: calc(50% - 200px);
    @media (max-width: 1000px) {
        margin: unset;
        width: 100%;


    }
}

.md-format {
    table {
        width: 100%;
        th, td {
            padding-left: 0.5rem!important;
            padding: 2px;
            border: 1px solid grey;

        }
    }
    ol, ul {
        line-height: 1.5rem
    }

}