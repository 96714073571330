
.trend-box {
    //min-height: 350px;
    background-color: #4b4c64 !important;
    margin-bottom: 10px;

    .title {
        font-size: 22px;
        //color: black;
        font-weight: 600
    }

    .description {
        padding-top: 20px;
        //color: black;
        font-size: 16px;
        font-weight: 500

    }
}

.db-coll {
    padding-bottom: 1rem;

}

.kind-img {
    height: 50px;
    margin-bottom: 10px;
}

@media (max-width: 1660px) {
    .db-coll {
        width: 33.3333% !important;
        padding-bottom: 1rem;
    }
}


@media (max-width: 900px) {
    .db-coll {
        width: 50% !important;
        padding-bottom: 1rem;
    }
}

@media (max-width: 600px) {
    .db-coll {
        width: 100% !important;
        padding-bottom: 1rem;
    }
}

@media (min-width: 1000px) {
    .menu-big {
        min-width: 70%;
    }
}

.menu-full {
    min-width: 100%;
}


@media (min-width: 1000px) {
    .border-right {
        border-right: 1px solid #a1a1a1;
    }

    .scrolling {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 80px);
        overflow-y: auto;
    }
}

.result-format-item {
    font-size: 16px;
    font-weight: 500;
    line-height: 2rem;
    font-style: normal;
    cursor: default;
    white-space: pre-line;
}

.border-bottom {
    border-bottom: 1px solid #a1a1a1;
}

.rs-loader-backdrop {
    background: rgb(255 255 255 / 76%) !important;
}

.rs-loader-content {
    font-size: 20px !important;
    font-weight: 700 !important;
}

.rs-loader-spin::after {
    border-width: 5px !important;
    border-color: #575757 transparent transparent !important;
}

.request {
    background: #444654;
}

.text-info {
    z-index: 0;
    max-width: 52rem;
    width: 100%;
    padding: 1rem;
    //padding-top: 1rem;
    white-space: pre-line;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: auto;
    //left: 50%;
}


@media (max-width: 1000px) {
    .text-info {
        width: 100% !important;
        padding: 0.7rem;

    }
}

.row-block {
    padding-bottom: 10rem;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 14px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}



